export const useSubscription = () => {
    const { execute, result, loading } = useApi(
        '/api/subscription/company',
        'GET'
    )

    const subscription = computed(() => {
        const price = result.value
        if (!price) {
            return null
        }

        return {
            id: price.id,
            free: false,
            name: price.name,
            description: price.description,
            is_pay_once: price.is_pay_once,
            price: price.price,
            currency: price.currency,
            discounts: price.discounts,
            taxes: price.taxes,
            order: price.order,
            featureNote: price.feature_note,
            features: price.features,
            endsAt: price.ends_at,
            nextPayAt: price.next_pay_at,
        }
    })

    return {
        execute,
        result,
        loading,
        subscription,
    }
}
